<template>
    <div class="row justify-content-center">
        <div class="col-lg-8 col-12 border my-1 bg-white">


            <!-- item block -->
            <div class="row text-left text-small justify-content-center py-3">     
                
                <!-- carret, icon and name -->
                <div class="col-lg-5 col-md-5 col-sm-9 col-12 text-primary">

                    <!-- carret v-b-toggle="'file-'+file.id" -->
                    <b-button 
                        variant="light" 
                        size="sm" 
                        :class="[!visible ? 'collapsed' : '', 'bg-transparent m-0 p-0 text-muted']"
                        @click="visible=!visible"
                    >
                        <b-icon :icon="visible ? 'caret-down-fill' : 'caret-right-fill'" />
                    </b-button>

                    <!-- file name -->
                    <span @click="imgExt.includes(file.extension) ? showImage(file) : download(file.name,file.title)" class="pointer">
                        <b-icon icon="file-earmark" class="ml-2" /> {{ file.title }}
                    </span>

                </div>

                <!-- fiel title if any -->
                <div class="col-lg-3 col-md-3 d-none d-md-block text-small text-muted">{{ info }}</div>
                
                <!-- file date -->
                <div class="col-lg-4 col-md-3 col-sm-3 d-none d-sm-block text-small text-muted text-right">{{ file.created_at }}</div>
                
                <!-- collapse row -->
            </div>

            <!-- collapsed description -->
            <b-collapse :id="'file-'+file.id" v-model="visible" class="row text-small text-left mb-2 pl-5">
                        <div class="col-12 text-muted">
                            <div class="d-inline d-md-none"><b>{{ file.title }}</b> – </div>{{ file.content }}
                            <div class="d-md-none text-tiny mt-2">{{ file.created_at }} – {{ info }}</div>
                        </div>
            </b-collapse>

        </div>
    </div>
</template>

<script>
export default {
    props: ['file'],
    data() {return{
        visible: false
    }},
    computed: {
        info() { return (this.file.size/1000000).toFixed(2) + " MB"; },
    },
    methods: {
        extension(name) { return name.split('.').pop(); }
    },
        
}
</script>