<template>
<div class="mb-4 container">

    <Header :title="$t('file.files')" />

    <div v-if="files.length>0">
        <SearchBar :text="'Find a file…'" v-model="search" />

        <div class="text-muted text-small text-center mb-2 pointer"
            @click="download('?p='+auth.project.id+'&zip=true',auth.project.title+'.zip')">
            download all files
        </div>

        <File v-for="file in filterFile" :key="file.id" :file="file" />
    </div>
    <!-- no access -->
    <Request :contents="files" :text="$t('file.none')" />
    
</div>
</template>

<script>
import SearchBar from "@/views/BaseComponents/SearchBar.vue";
import File from "@/views/FileComponents/File.vue";
import Request from "@/views/BaseComponents/Request.vue";

export default {
    name: "Files",
    components: {
        SearchBar, File, Request
    },
    async created() { await this.getFiles(); },
    computed: {
        filterFile() {
            return this.files.filter(file => {
                return  file.title.toLowerCase().includes(this.search.toLowerCase()) ||
                        file.content.toLowerCase().includes(this.search.toLowerCase()) ||
                        file.created_at.includes(this.search);
            });
        }
    },
    data() {return{
        search: '',
        files: []
    }},

    methods: {
        async getFiles() { 
            let data = await this.axon('get','files?p='+this.auth.project.id); 
            if(data) this.files = data;
        },
    },
}
</script>